import React from 'react'
const { useState, useEffect } = React;

const TimeCountDown = (props) => {
  const [countdownDate, setCountdownDate] = useState(new Date(2024, 5, 1, 12).getTime());
  const [state, setState] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    setInterval(() => setNewTime(), 1000);
  }, []);

  const setNewTime = () => {
    if (countdownDate) {
      const currentTime = new Date().getTime();

      const distanceToDate = countdownDate - currentTime;

      let days = Math.floor(distanceToDate / (1000 * 60 * 60 * 24));
      let hours = Math.floor(
        (distanceToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
      );
      let minutes = Math.floor(
        (distanceToDate % (1000 * 60 * 60)) / (1000 * 60),
      );
      let seconds = Math.floor((distanceToDate % (1000 * 60)) / 1000);

      setState({ days: days, hours: hours, minutes, seconds });
    }
  };

  const getDays = (days) => {
    if (days === 1) {
      return 'Den';
    } else if (days <= 4) {
      return 'Dny';
    } else {
      return 'Dnů';
    }
  }

  const getHours = (hours) => {
    console.log(hours)
    if (hours === 1) {
      return 'Hodina';
    } else if (hours <= 4) {
      return 'Hodiny';
    } else {
      return 'Hodin';
    }
  }

  const getMinutes = (minutes) => {
    if (minutes === 1) {
      return 'Minuta';
    } else if (minutes <= 4) {
      return 'Minuty';
    } else {
      return 'Minut';
    }
  }

  const getSeconds = (seconds) => {
    if (seconds === 1) {
      return 'Sekunda';
    }
    else if (seconds <= 4) {
      return 'Sekundy';
    } else {
      return 'Sekund';
    }
  }

  const formatTime = (time) => {
    return time < 10 ? `0${time}` : time;
  }

  return (
    <div>
      <div className={`wpo-wedding-date ${props.dateClass}`}>
        <div className="react-countdown">
          <div className='time-section'>
            <div className='time'>{formatTime(state.days)}</div>
            <small className="time-text">{getDays(state.days || 0)}</small>
          </div>
          <div className='time-section'>
            <div className='time'>{formatTime(state.hours)}</div>
            <small className="time-text">{getHours(state.hours || 0)}</small>
          </div>
          <div className='time-section'>
            <div className='time'>{formatTime(state.minutes)}</div>
            <small className="time-text">{getMinutes(state.minutes || 0)}</small>
          </div>
          <div className='time-section'>
            <div className='time'>{formatTime(state.seconds)}</div>
            <small className="time-text">{getSeconds(state.seconds || 0)}</small>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeCountDown;

