import React from "react";
import {witnesses, drivers} from '../../api/team'
import SectionTitleS2 from '../SectionTitleS2'


const TeamSection = (props) => {
  return (
    <section className="wpo-team-section section-padding" id="contact">
      <div className="container">
        <SectionTitleS2 MainTitle={'Kontakty'} />
        <div className="wpo-team-wrap">
          <div className="row">
            {witnesses.slice(0, 6).map((Team, tm) => (
              <div className="col-sm-6 col-12" key={tm}>
                  <div className="wpo-team-item">
                    <div className="wpo-team-img">
                      <img src={Team.tImg} alt="" />
                    </div>
                    <div className="wpo-team-text">
                      <h3>{Team.name}</h3>
                      <div>{Team.title}</div>
                      <div>{Team.phone}</div>
                    </div>
                  </div>
              </div>
            ))}
          </div>

          {/* <div className="row mt-12">
            {drivers.slice(0, 6).map((Team, tm) => (
              <div className="col-sm-6 col-12" key={tm}>
                  <div className="wpo-team-item">
                    <div className="wpo-team-img">
                      <img src={Team.tImg} alt="" />
                    </div>
                    <div className="wpo-team-text">
                      <h3>{Team.name}</h3>
                      <div>{Team.title}</div>
                      <div>{Team.phone}</div>
                    </div>
                  </div>
              </div>
            ))}
          </div> */}
        </div>
      </div>
    </section>
  )
}

export default TeamSection;