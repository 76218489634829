import React from 'react'
import SectionTitleS2 from '../SectionTitleS2'


const About = (props) => {
    return(
        <section className="wpo-about-section section-padding--small" id="additional">
        <div className="container">
        <SectionTitleS2 MainTitle={'Další informace'} />
            <div className="row align-items-center">
                <div className="col-md-12 col-12">
                    <div className="wpo-about-text">
                        <div className="wpo-about-icon-content">
                          <div className="flex items-center mb-3">
                            </div>
                            <h3>Dress Code</h3>
                            <p>
                            Nevěsta s ženichem budou laděni do modré barvy. Pokud nás chcete barevně doplnit, budeme rádi, ale není to nutné!
                            Oblečte si cokoliv, co je pro vás pohodlné, barvu jakoukoli máte rádi a berte ohled i na počasí.
                            </p>
                            <h3>Dary</h3>
                            <p>
                            Nevíte si rady s dary, nápověda už je tady. Když korunkou přispějete, na domeček, na rodinku, budeme moc rádi, důležité však pro nás bude, že budete s námi! Děkujeme!
                            </p>
                            <h3>Řidiči</h3>
                            <p>
                            Na svatbě budou k dispozici řidiči, kteří Vás mohou odvést domů. Kontakt na ně bude dostupný na svatbě.
                            </p>
                            <h3>Snídaně</h3>
                            <p>
                            S těmi z vás, kteří zůstanou přes noc, se rádi setkáme na snídani. 
                            Snídaně nebude nijak organizovaná, dojděte, jakmile se vám bude chtít. 
                            Bude zajištěné drobné občerstvení a k tomu samozřejmě dobrá káva nebo čaj :)
                            </p>
                            <h3>Nedělní odjezd</h3>
                            <p>
                            V neděli žádné spěchy, času na snídani a rozvičku budete mít dost. 
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
}

export default About;