import david from '../images/contact/david.webp'
import tomas from '../images/contact/tomas.webp'
import misa from '../images/contact/misa.webp'
import lukas from '../images/contact/lukas.webp'


export const witnesses = [
    {
       id:'1',
       tImg:misa,
       name:'Míša Suková',
       title:'Svědkyně',  
      //  phone: '725 643 464',   
    },
    {
       id:'2',
       tImg:lukas,
       name:'Lukáš Charvát',
       title:'Svěděk',     
      //  phone: '605 155 543',   
    },
]

export const drivers = [
  {
     id:'1',
     tImg:tomas,
     name:'Tomáš Půček',
     title:'Řidič',  
     phone: 'Číslo zveřejníme den před svatbou',   
    },
  // {
  //    id:'2',
  //    tImg:david,
  //    name:'David Zitta',
  //    title:'Řidič',  
  //    phone: 'Číslo zveřejníme den před svatbou',   
  //   },
 
]