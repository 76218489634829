import React from 'react'
// import {Link} from 'react-router-dom'
// import abimg from '../../images/about/1.png'
// import abimg2 from '../../images/about/2.png'
import abimg3 from '../../images/about/shape.png'
import abimg4 from '../../images/about/thumb.png'
import ArealImg from '../../images/location/lysovice-areal.webp'
import flower from '../../images/our-assets/flower.png'


const About = (props) => {
    return(
        <section className="wpo-about-section section-padding" id="location">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-5 col-md-12 col-12">
                    <div className="wpo-about-wrap flex">
                            <img src={flower} alt="" className="areal-flower"/>
                        <div className="wpo-about-item">
                            <div className="wpo-about-img">
                                <img src={ArealImg} alt=""/>
                            </div>
                        </div>
                        {/* <div className="about-single-item">
                            <Roll left>
                                <div className="wpo-about-item-s2">
                                    <div className="wpo-about-img">
                                        <img src={abimg2} alt=""/>
                                    </div>
                                </div>
                            </Roll>
                        </div> */}
                 
                    </div>
                </div>
                <div className="col-lg-7 col-md-12 col-12">
                    <div className="wpo-about-text">
                        {/* <div className="wpo-about-icon">
                            <div className="icon">
                                <img src={abimg4} alt=""/>
                            </div>
                        </div> */}
                        <div className="wpo-about-icon-content">
                            <h2>Lysovický rybník</h2>
                            <p>
                              Svatba se bude konat v areálu Lysovického rybníka.
                              Obřad bude probíhat venku, takže se prosím připravte na jakékoliv počasí. 
                              </p>
                            <p>
                                Na místo je potřeba přijet ze směru od Rostěnic-Zvonovic. 
                                Jakmile vás v Lysovicích navigace povede za hřištěm na polní cestu, věřte jí a pokračujte dál.
                                Po zhruba 1km dojedete k rozlehlému areálu rybníka, kde se bude svatba konat.
                              </p>
                            {/* <Link onClick={ClickHandler} to="#map" className="theme-btn-s3" >Ukázat mapu</Link> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
}

export default About;