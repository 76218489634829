import React from 'react'
// import {Link} from 'react-router-dom'
// import abimg from '../../images/about/1.png'
// import abimg2 from '../../images/about/2.png'
import abimg3 from '../../images/about/shape.png'
import abimg4 from '../../images/about/thumb.png'
import ArealImg from '../../images/location/lysovice-areal.jpg'
import SectionTitleS2 from '../SectionTitleS2'


const About = (props) => {
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }
    return(
        <section className="wpo-about-section section-padding" id="accomodation">
        <div className="container">
        <SectionTitleS2 MainTitle={'Stanování a ubytování v okolí'} />
            <div className="row align-items-center">
                <div className="col-md-12 col-12">
                    <div className="wpo-about-text">
                        <div className="wpo-about-icon-content">
                          <div className="flex items-center mb-3">
                            {/* <div className="icon mr-2">
                                <img src={abimg4} alt=""/>
                            </div> */}
                            </div>
                            <p>
                              Pokud máte rádi dobrodružství, neváhejte si dovézt stan a přespat na místě.
                            </p>
                            <p>
                              Pro ty z vás, kteří budete po dobré zábavě chtít spát ve své posteli doma, máme zajištěné <a href="#contact">řidiče</a>, 
                              kteří Vás mohou odvézt domů.
                            </p>

                            <p>
                              A pokud byste chtěli strávit noc poblíž, můžeme doporučit &nbsp;  
                              <a href="https://www.malafarmanemojany.cz/" target="_blank" rel="noreferrer">Penzion malá farma</a> nebo <a href="http://www.ubytovani-lulec.cz/ubytovani/  " target="_blank" rel="noreferrer">Penzion Pohoda</a>.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
}

export default About;