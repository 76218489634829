import React from 'react'
import SectionTitleS2 from '../SectionTitleS2'
// import pImg1 from '../../images/portfolio/7.jpg'
// import pImg2 from '../../images/portfolio/8.jpg'
// import pImg3 from '../../images/portfolio/9.jpg'
// import pImg4 from '../../images/portfolio/10.jpg'
// import pImg5 from '../../images/portfolio/11.jpg'
// import pImg6 from '../../images/portfolio/12.jpg'

import img2014_08 from '../../images/our-featured/2014_08.png'
import thumbnail2014_08 from '../../images/our-featured/thumbnail_2014_08.png'
import img2014_11 from '../../images/our-featured/2014_11.png'
import thumbnail2014_11 from '../../images/our-featured/thumbnail_2014_11.png'
import img2014_12 from '../../images/our-featured/2014_12.png'
import thumbnail2014_12 from '../../images/our-featured/thumbnail_2014_12.png'
import img2015_03 from '../../images/our-featured/2015_03.png'
import thumbnail2015_03 from '../../images/our-featured/thumbnail_2015_03.png'
import img2015_09a from '../../images/our-featured/2015_09a.png'
import thumbnail2015_09a from '../../images/our-featured/thumbnail_2015_09a.png'
import img2015_10 from '../../images/our-featured/2015_10.png'
import thumbnail2015_10 from '../../images/our-featured/thumbnail_2015_10.png'
import img2016_08 from '../../images/our-featured/2016_08.png'
import thumbnail2016_08 from '../../images/our-featured/thumbnail_2016_08.png'
import img2017_03 from '../../images/our-featured/2017_03.png'
import thumbnail2017_03 from '../../images/our-featured/thumbnail_2017_03.png'
import img2017_05 from '../../images/our-featured/2017_05.png'
import thumbnail2017_05 from '../../images/our-featured/thumbnail_2017_05.png'
import img2018_03a from '../../images/our-featured/2018_03a.png'
import thumbnail2018_03a from '../../images/our-featured/thumbnail_2018_03a.png'
import img2018_03b from '../../images/our-featured/2018_03b.png'
import thumbnail2018_03b from '../../images/our-featured/thumbnail_2018_03b.png'
import img2018_03c from '../../images/our-featured/2018_03c.png'
import thumbnail2018_03c from '../../images/our-featured/thumbnail_2018_03c.png'
import img2018_03d from '../../images/our-featured/2018_03d.png'
import thumbnail2018_03d from '../../images/our-featured/thumbnail_2018_03d.png'
import img2018_03e from '../../images/our-featured/2018_03e.png'
import thumbnail2018_03e from '../../images/our-featured/thumbnail_2018_03e.png'
import img2019_02a from '../../images/our-featured/2019_02a.png'
import thumbnail2019_02a from '../../images/our-featured/thumbnail_2019_02a.png'
import img2019_02b from '../../images/our-featured/2019_02b.png'
import thumbnail2019_02b from '../../images/our-featured/thumbnail_2019_02b.png'
import img2022_05a from '../../images/our-featured/2022_05a.png'
import thumbnail2022_05a from '../../images/our-featured/thumbnail_2022_05a.png'
import img2022_05b from '../../images/our-featured/2022_05b.png'
import thumbnail2022_05b from '../../images/our-featured/thumbnail_2022_05b.png'
import img2022_05c from '../../images/our-featured/2022_05c.png'
import thumbnail2022_05c from '../../images/our-featured/thumbnail_2022_05c.png'
import img2022_05d from '../../images/our-featured/2022_05d.png'
import thumbnail2022_05d from '../../images/our-featured/thumbnail_2022_05d.png'
import img2022_05e from '../../images/our-featured/2022_05e.png'
import thumbnail2022_05e from '../../images/our-featured/thumbnail_2022_05e.png'
import img2022_08 from '../../images/our-featured/2022_08.png'
import thumbnail2022_08 from '../../images/our-featured/thumbnail_2022_08.png'
import img2023_08a from '../../images/our-featured/2023_08a.png'
import thumbnail2023_08a from '../../images/our-featured/thumbnail_2023_08a.png'
import img2023_08b from '../../images/our-featured/2023_08b.png'
import thumbnail2023_08b from '../../images/our-featured/thumbnail_2023_08b.png'
import img2023_09a from '../../images/our-featured/2023_09a.png'
import thumbnail2023_09a from '../../images/our-featured/thumbnail_2023_09a.png'
import img2023_09b from '../../images/our-featured/2023_09b.png'
import thumbnail2023_09b from '../../images/our-featured/thumbnail_2023_09b.png'
import img2023_09c from '../../images/our-featured/2023_09c.png'
import thumbnail2023_09c from '../../images/our-featured/thumbnail_2023_09c.png'
import img2023_09d from '../../images/our-featured/2023_09d.png'
import thumbnail2023_09d from '../../images/our-featured/thumbnail_2023_09d.png'
import img2023_09e from '../../images/our-featured/2023_09e.png'
import thumbnail2023_09e from '../../images/our-featured/thumbnail_2023_09e.png'

import ReactFancyBox from 'react-fancybox'
import 'react-fancybox/lib/fancybox.css'


const Portfolios = [
      {
      img: img2014_08,
      thumbnail: thumbnail2014_08,
      caption: 'Cyklovýlet na Vysočině (Srpen 2014)'
    },
    {
      img: img2014_11,
      thumbnail: thumbnail2014_11,
      caption: "Domčiny dvacetiny (Listopad 2014)"
    },
    {
      img: img2014_12,
      thumbnail: thumbnail2014_12,
      caption: "Vánoční trhy v Budapešti (Prosinec 2014)"
    },
    {
      img: img2015_03,
      thumbnail: thumbnail2015_03,
      caption: "Barcelona (Březen 2015)"
    },
    {
      img: img2015_09a,
      thumbnail: thumbnail2015_09a,
      caption: "Amerika (Září 2015)"
    }, 
    {
      img: img2015_10,
      thumbnail: thumbnail2015_10,
      caption: "Spartan race Lipno (Říjen 2015)"
    },
    {
      img: img2016_08,
      thumbnail: thumbnail2016_08,
      caption: "Slovinsko (Srpen 2016)"
    },
    {
      img: img2017_03,
      thumbnail: thumbnail2017_03,
      caption: "Výlet Buchlovice (Březen 2017)",
    },
    {
      img: img2017_05,
      thumbnail: thumbnail2017_05,
      caption: "Paříž (Květen 2017)"
    },
    {
      img: img2018_03a,
      thumbnail: thumbnail2018_03a,
      caption: "Tenerife (Březen 2018)"
    },
    {
      img: img2018_03b,
      thumbnail: thumbnail2018_03b,
      caption: "Tenerife (Březen 2018)"
    },
    {
      img: img2018_03c,
      thumbnail: thumbnail2018_03c,
      caption: "Tenerife (Březen 2018)"
    },
    {
      img: img2018_03d,
      thumbnail: thumbnail2018_03d,
      caption: "Tenerife (Březen 2018)"
    },
    {
      img: img2018_03e,
      thumbnail: thumbnail2018_03e,
      caption: "Tenerife (Březen 2018)"
    },
    {
      img: img2019_02a,
      thumbnail: thumbnail2019_02a,
      caption: "Egypt (Únor 2019)"
    },
    {
      img: img2019_02b,
      thumbnail: thumbnail2019_02b,
      caption: "Egypt (Únor 2019)"
    },
    {
      img: img2022_05a,
      thumbnail: thumbnail2022_05a,
      caption: "Těhotenské focení (Květen 2022)"
    },
    {
      img: img2022_05b,
      thumbnail: thumbnail2022_05b,
      caption: "Těhotenské focení (Květen 2022)"
    },
    {
      img: img2022_05c,
      thumbnail: thumbnail2022_05c,
      caption: "Těhotenské focení (Květen 2022)"
    },
    // {
    //   img: img2022_05d,
    //   thumbnail: thumbnail2022_05d,
    //   caption: "Šumava (Květen 2022)"
    // },
    // {
    //   img: img2022_05e,
    //   thumbnail: thumbnail2022_05e,
    //   caption: "Šumava (Květen 2022)"
    // },
    {
      img: img2022_08,
      thumbnail: thumbnail2022_08,
      caption: "Svatba Uhlířových (Srpen 2022)"
    },
    {
      img: img2023_08a,
      thumbnail: thumbnail2023_08a,
      caption: "Slovensko (Srpen 2023)"
    },
    {
      img: img2023_08b,
      thumbnail: thumbnail2023_08b,
      caption: "Slovensko (Srpen 2023)"
    },
    {
      img: img2023_09a,
      thumbnail: thumbnail2023_09a,
      caption: "Rodinné focení (Září 2023)"
    },
    {
      img: img2023_09b,
      thumbnail: thumbnail2023_09b,
      caption: "Rodinné focení (Září 2023)"
    },
    {
      img: img2023_09c,
      thumbnail: thumbnail2023_09c,
      caption: "Rodinné focení (Září 2023)"
    },
    {
      img: img2023_09d,
      thumbnail: thumbnail2023_09d,
      caption: "Rodinné focení (Září 2023)"
    },
    {
      img: img2023_09e,
      thumbnail: thumbnail2023_09e,
      caption: "Rodinné focení (Září 2023)"
    }
] 

const PortfolioSection = (props) => {
    return(
        <section className={`row wpo-portfolio-section-s2 section-padding ${props.pSclass}`} id="gallery">
            <div className="container">
                <SectionTitleS2 MainTitle={'Galerie'}/>
                <div className="sortable-gallery">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="portfolio-grids gallery-container clearfix portofolio-images-container">
                                {Portfolios.map((portfolio, pitem) => (
                                    <div className="portofolio-image" key={pitem}>
                                        <div className="img-holder">
                                            <ReactFancyBox
                                                thumbnail={portfolio.thumbnail}
                                                image={portfolio.img}
                                                caption={portfolio.caption}
                                                />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PortfolioSection;