import React from 'react'
import SectionTitleS2 from '../SectionTitleS2'
import sImg1 from '../../images/portfolio/1.jpg'
import sImg2 from '../../images/portfolio/2.jpg'
import sImg3 from '../../images/portfolio/3.jpg'
import sImg4 from '../../images/portfolio/4.jpg'

import Timi from "../../images/our-story/timi.webp"
import Engagement from "../../images/our-story/engagement.webp"
import Family from "../../images/our-story/family.webp"
import BikeTrip from "../../images/our-story/bike_trip.webp"
import Barcelona from "../../images/our-story/barcelona.webp"


import shape from '../../images/story/shape.png'
import FlowerFrame from '../../images/our-assets/flower-frame.svg'




const StorySection = (props) => {
    const images = {
      engagement: {
        backgroundImage: `url(${Engagement})`
      },
      timi: {
        backgroundImage: `url(${Timi})`
      },
      family: {
        backgroundImage: `url(${Family})`
      },
      bikeTrip: {
        backgroundImage: `url(${BikeTrip})`
      },
      barcelona: {
        backgroundImage: `url(${Barcelona})`
      }
    }

    return(
        <section className="story-section section-padding" id="story">
            <div className="container">
                <SectionTitleS2 MainTitle={'O Domči a Danovi'}/>
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="story-timeline">
                            <div className="round-shape"></div>
                            <div className="row">
                                <div className="col col-lg-6 col-12">
                                    <div className="story-text right-align-text">
                                        <h3>Jak to všechno začalo</h3>
                                        <span className="date">Gympl</span>
                                        <p>Domča dojížděla od 2. stupně základní školy do Brna a od jisté doby vídala Dana z autobusu. Už tenkrát se jí líbil. 
                                          Nicméně až na vánoce v roce 2013 je štěstěna a společní kamarádi posadili na hlasité oslavě v restauraci Na Hradbách vedle sebe.</p>
                                    </div>
                                </div>
                                <div className="col col-lg-6 col-12">
                                    <div className="story-image">
                                        {/* <img src={sImg1} alt="" className="img img-responsive our-story-image"/> */}
                                        <div className="our-story-image" style={images.family} />
                                        <div className="story-shape-wrapper">
                                            <img src={FlowerFrame} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col col-lg-6 col-12">
                                    <div className="story-image right-align-text left-site">
                                        {/* <img src={sImg2} alt="" className="img img-responsive our-story-image"/> */}
                                        <div className="our-story-image" style={images.bikeTrip} />
                                        <div className="story-shape-wrapper">
                                            <img src={FlowerFrame} alt=""/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-lg-6 col-12 text-holder">
                                    <span className="heart">
                                        <i className="fi flaticon-heart"></i>
                                    </span>
                                    <div className="story-text">
                                        <h3>První rande a pusa</h3>
                                        <span className="date">29. 5. 2014</span>
                                        <p>První rande proběhlo opět v restauraci Na Hradbách, avšak jen jeden z nich věděl, že se o rande jedná. 
                                          Až po pizze, šperglích, láhvi vína a 10km procházce později se Dan odhodlal Domču zasvětit, že se skutečně o rande jedná a přišla první pusa.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col col-lg-6 col-12 text-holder right-heart">
                                    <span className="heart">
                                        <i className="fi flaticon-calendar"></i>
                                    </span>
                                    <div className="story-text right-align-text">
                                        <h3>Cestovatelská sedmiletka</h3>
                                        <span className="date">2014-2021</span>
                                           <p>
                                            Dan a  Domča se spolu podívali postupně na spousty míst v Evropě i mimo ni. Po měsíci chození byli na měsíc odloučení,
                                            kdy Domča zamířila do Skotska a Dan do Portugalska.  V roce 2015 byli oba na 4 měsíce v USA, ale každý jinde. V roce 2016 byla Domča na Erasmu
                                           ve Francii, Dan v jižní Koreji. 
                                            Na kratší výlety se spolu podívali na Slovinsko, Malorku, Tenerife, do Paříže, Egypta, nebo Maďarska. Dan trávil nějaký čas v Německu, 
                                            Domča zase projela část Estonska a Helsinki. 
                                            Hory v Rakousku a moře v Chorvatsku byly téměř každoroční tradicí. Po Česku Dana brala Domča na svá oblíbená místa, například do Adršpachu.</p>
                                    </div>
                                </div>
                                <div className="col col-lg-6 col-12">
                                    <div className="story-image right-align-text">
                                        {/* <img src={Engagement} alt="" className="img img-responsive our-story-image"/> */}
                                        <div className="our-story-image" style={images.barcelona} />
                                        <div className="story-shape-wrapper">
                                            <img src={FlowerFrame} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col col-lg-6 col-12">
                                    <div className="story-image left-site">
                                        {/* <img src={Timi} alt="" className="img img-responsive our-story-image"/> */}
                                        <div className="our-story-image" style={images.engagement} />
                                        <div className="story-shape-wrapper">
                                            <img src={FlowerFrame} alt=""/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-lg-6 col-12 text-holder">
                                    <span className="heart">
                                        <i className="fi flaticon-dove"></i>
                                    </span>
                                    <div className="story-text">
                                    <h3>Zásnuby</h3>
                                        <span className="date">29.5. 2021</span>
                                        <p>Strávili spolu pestrých 7 let, než přišly zásnuby. Na víkendovém pobytu v Ralsku se Dan rozhodl Domču požádat o ruku na klidném 
                                          a malebném místě v přírodě. Místo vybrala v podstatě Domča, když se zmínila o Sloupu v Čechách, kam se vždy chtěla podívat.
                                          Nervozita byla výrazná a Domča se již od rána bála, že Dan má žaludeční problémy, než přišla konečně žádost. Domča po několika vteřinovém
                                           zmatečném žvatlání řekla nadšené SAMOZŘEJMĚ.</p>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="row">
                                <div className="col offset-lg-6 col-lg-6 col-12 text-holder">
                                    <span className="heart">
                                        <i className="fi flaticon-wedding-rings"></i>
                                    </span>
                                </div>
                            </div> */}
                            <div className="row">
                                <div className="col col-lg-6 col-12 text-holder right-heart">
                                    <span className="heart">
                                        <i className="fi flaticon-calendar"></i>
                                    </span>
                                    <div className="story-text right-align-text">
                                    <h3>Radost jménem Timi</h3>
                                        <span className="date">21. 6. 2022</span>
                                        <p>Než stihli zorganizovat svatbu, potkala je radost největší. Na letní slunovrat v roce 2022 se jim narodil krásný a velmi energický chlapeček jménem Timon.</p>



                                           {/* <p>Cestovatelská sedmiletka: 2014-2021
                                            Dan a  Domča se spolu podívali postupně na spousty míst v Evropě i mimo ni. Po měsíci chození byli na měsíc odloučení,
                                            kdy Domča zamířila do Skotska a Dan do Portugalska.  V roce 2015 byli oba na 4 měsíce v USA, ale každý jinde. V roce 2016 byla Domča na Erasmu
                                           ve Francii, Dan v jižní Koreji. 
                                            Na kratší výlety se spolu podívali na Slovinsko, Malorku, Tenerife, do Paříže, Egypta, nebo Maďarska. Dan trávil nějaký čas i v Německu, 
                                            Domča zase projela část Estonska a Helsinki. 
                                            Hory v Rakousku a moře v Chorvatsku bylo téměř každoroční tradicí. Po Česku Dana brala Domča na svá oblíbená místa, například do Adršpachu.</p> */}
                                    </div>
                                </div>
                                <div className="col col-lg-6 col-12">
                                    <div className="story-image right-align-text">
                                        {/* <img src={Engagement} alt="" className="img img-responsive our-story-image"/> */}
                                        <div className="our-story-image" style={images.timi} />
                                        <div className="story-shape-wrapper">
                                            <img src={FlowerFrame} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default StorySection;