import React from 'react'
import SectionTitleS2 from '../SectionTitleS2'
import sImg1 from '../../images/event-shape-1.png'
import sImg2 from '../../images/event-shape-2.png'


const Events = [
    {
        Simg1: sImg1,
        Simg2: sImg2,
        title:'Obřad',
        li1:'Přijeďte prosím nejpozději v 11 hodin, abyste stihli zaparkovat, pozdravit se a shromáždit se na obřad.',
        li2:'V pravé poledne, ve 12:00, si řekneme své ANO',
    },
    {
        Simg1: sImg1,
        Simg2: sImg2,
        title:'Focení a oběd',
        li1:'Po obřadu se těšíme na společné focení s vámi všemi. Jednotlivé skupiny budou vyvolávány.',
        li2:'Po focení cca ve 14 hodin se přesuneme na oběd pod pergolou. Po obědě proběhne krájení dortu.',
    },
    {
        Simg1: sImg1,
        Simg2: sImg2,
        title:'Raut a zábava',
        li1:'Od 17:30 hodin bude připravený raut, dále se můžete těšit na různé svatební hry, první tanec novomanželů a na pořádnou svatební párty!',
        li2:'Časově neomezeno.',
    },

]

const EventSection = (props) => {
    return (
        <section className={`wpo-event-section section-padding ${props.eClass}`} id="events">
            <div className="container">
                <SectionTitleS2 MainTitle={'PROGRAM'} />
                <div className="wpo-event-wrap">
                    <div className="row">
                        {Events.map((event, eitem) => (
                            <div className="col col-lg-4 col-md-6 col-12" key={eitem}>
                                <div className="wpo-event-item">
                                    <div className="wpo-event-text">
                                        <h2>{event.title}</h2>
                                        <ul>
                                            <li>{event.li1}</li>
                                            <li>{event.li2}</li>
                                            <li>{event.li3}</li>
                                        </ul>
                                    </div>
                                    <div className="event-shape-1">
                                        <img src={event.Simg1} alt="" />
                                    </div>
                                    <div className="event-shape-2">
                                        <img src={event.Simg2} alt="" />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default EventSection;