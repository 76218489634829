import React from 'react'
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';

const libraries = ['places'];
const mapContainerStyle = {
  width: '100vw',
  height: '400px',
};
const center = {
  lat: 49.2206228, // default latitude
  lng: 16.9755363, // default longitude
};
const marker = {
  lat: 49.2070061, // default latitude
  lng: 16.9755363, // default longitude
};


const CtaSection2 = (props) => {
      const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: 'AIzaSyAr5z6gyXRwGTV-9LjVXXnCRSNQIN-SOkQ',
        libraries,
      });
    
      if (loadError) {
        return <div>Error loading maps</div>;
      }
    
      if (!isLoaded) {
        return <div>Loading maps</div>;
      }

      return (
        <div>
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            zoom={13}
            center={center}
          >
            <Marker position={marker} />
          </GoogleMap>
        </div>
      );

}

export default CtaSection2;