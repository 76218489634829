import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import HomePage5 from '../HomePage5';
import ErrorPage from '../ErrorPage'

function RedirectGallery() {
  window.location.replace('https://disposable.app/account/event/10758/photos');
}

const AllRoute = () => {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path='/' element={<HomePage5/>} />
          <Route exact path='/galerie' element={<RedirectGallery />}/>
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default AllRoute;
